// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "y_th d_gv d_cs";
export var heroHeaderCenter = "y_gw d_gw d_cs d_dw";
export var heroHeaderRight = "y_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "y_tj d_gr d_cw";
export var heroParagraphCenter = "y_gs d_gs d_cw d_dw";
export var heroParagraphRight = "y_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "y_tk d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "y_tl d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "y_tm d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "y_tn d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "y_tp d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "y_tq z_tq";
export var heroExceptionNormal = "y_tr z_tr";
export var heroExceptionLarge = "y_ts z_ts";
export var Title1Small = "y_tt z_tt z_tB z_tC";
export var Title1Normal = "y_tv z_tv z_tB z_tD";
export var Title1Large = "y_tw z_tw z_tB z_tF";
export var BodySmall = "y_tx z_tx z_tB z_tV";
export var BodyNormal = "y_ty z_ty z_tB z_tW";
export var BodyLarge = "y_tz z_tz z_tB z_tX";